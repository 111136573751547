import React from "react";
import playStore from "../images/get-it-on-google-play.png";
import appStore from "../images/get-it-on-app-store.svg";
import logo from "../images/tovivu_main_logo_transparent.png";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

function PaymentSuccesful() {
  // const location = useLocation();
  // const navigate = useNavigate();

  // useEffect(() => {
  //   if (!location.state?.fromPayment) {
  //     navigate("/");
  //   }
  // }, [location, navigate]);
  return (
    <div className="flex flex-row items-center justify-center h-[80vh]">
      <div>
        <div className="logoArea w-full flex items-center justify-center">
          <img src={logo} className="w-44 lg:w-[13vw]" alt="Logo" />
        </div>
        <div className="marketArea w-full flex items-center justify-center">
          <Link
            to="https://play.google.com/store/apps/details?id=com.tovivu.client.app&hl=tr"
            target="_blank"
          >
            <img
              src={playStore}
              className="w-36 lg:w-[13vw] h-9 lg:h-[57px] rounded-lg"
              alt="Play Store"
            />
          </Link>
          <Link
            to="https://apps.apple.com/tr/app/tovivu/id6477754512"
            target="_blank"
          >
            <img
              src={appStore}
              className="w-36 lg:w-[13vw] h-36 lg:h-[13vw] ml-4 lg:ml-8"
              alt="App Store"
            />
          </Link>
        </div>
        <div className="textArea w-full flex flex-col items-center justify-center">
          <h1 className="lg:text-[1.2vw] text-white mt-16 text-center text-sm px-4">
            Ödeme işlemi başarılı bir şekilde tamamlandı. Uygulamaya girip hemen
            kullanmaya başlayabilirsin 😉.
          </h1>
        </div>
      </div>
    </div>
  );
}

export default PaymentSuccesful;
