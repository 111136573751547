import React from "react";
import i1 from "../images/section4/fourthSection1.png";

function FourthSection() {
  return (
    <div className="bg-[#fffbfa] py-24 px-8 lg:px-16">
      <div className="generalDiv flex flex-col lg:flex-row lg:px-8">
        <div className="textArea lg:w-1/2 flex items-center justify-center lg:p-8">
          <div>
            <div className="titles flex flex-row text-3xl lg:text-6xl font-bold">
              <h1 className="text-yellow-500">uyurken dahi</h1>
            </div>
            <p className="text-gray-600 font-normal my-4 text-base lg:text-xl">
              Ders çalışmak çok zor ve yorucudur. Peki sen hiç uyurken dahi ders
              dinlediğine şahit oldun mu ? Hey bir dakika sınıf ortamından hariç
              !
            </p>
            <button className="bg-black text-white rounded-3xl py-2 px-12 font-medium ">
              Hemen Abone Ol
            </button>
          </div>
        </div>
        <div className="imageArea flex flex-row items-center justify-center lg:w-1/2 mt-5 lg:mt-0">
          <div>
            <div className="flex items-center justify-center lg:h-[430px] lg:w-[680px]  lg:m-5">
              <img
                src={i1}
                alt="Image 1"
                className="w-full h-full object-cover rounded-3xl"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FourthSection;
