import React from "react";
import i1 from "../images/section3/thirdSection1.png";
import i2 from "../images/section3/thirdSection2.png";
import i3 from "../images/section3/thirdSection3.png";
import i4 from "../images/section3/thirdSection4.png";

function ThirdSection() {
  return (
    <div className="bg-[#fffbfa] px-8 lg:px-16">
      <div className="generalDiv flex flex-col lg:flex-row lg:px-8">
        <div className="textArea lg:w-1/2 flex items-center justify-center lg:p-8">
          <div>
            <div className="titles flex flex-row text-3xl lg:text-6xl font-bold">
              <h1 className="text-black">
                <span className="text-yellow-500">t</span>ov
                <span className="text-yellow-500">i</span>vu
              </h1>
              <h1 className="text-orange-600 ml-4">Original</h1>
            </div>
            <p className="text-gray-600 font-normal my-4 text-base lg:text-xl">
              Başka bir yerde bulamayacağın,{" "}
              <span className="font-bold">tovivu</span> kullanıcılarına özel
              oluşturulmuş benzersiz içerikler seni bekliyor.
            </p>
            <button className="bg-black text-white rounded-3xl py-2 px-12 font-medium ">
              Hemen Abone Ol
            </button>
          </div>
        </div>
        <div className="imageArea flex flex-col items-center justify-center lg:w-1/2 mt-5 lg:mt-0">
          <div className="flex flex-row w-full lg:w-auto">
            <div className="flex items-center justify-center lg:h-[450px] lg:w-[200px] h-[230px] w-1/3 m-2 lg:m-5">
              <img
                src={i1}
                alt="Image 1"
                className="w-full h-full object-cover rounded-3xl"
              />
            </div>
            <div className="flex items-center justify-center lg:h-[450px] lg:w-[200px] h-[230px] w-1/3 m-2 lg:m-5">
              <img
                src={i2}
                alt="Image 2"
                className="w-full h-full object-cover rounded-3xl"
              />
            </div>
            <div className="flex items-center justify-center lg:h-[450px] lg:w-[200px] h-[230px] w-1/3 m-2 lg:m-5">
              <img
                src={i3}
                alt="Image 3"
                className="w-full h-full object-cover rounded-3xl"
              />
            </div>
          </div>
          <div className="flex flex-row w-full lg:w-auto">
            <div className="flex items-center justify-center lg:h-[320px] lg:w-[680px] m-2 lg:m-5">
              <img
                src={i4}
                alt="Image 1"
                className="w-full h-full object-cover rounded-3xl"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ThirdSection;
