import React from "react";

function SixthSection() {
  return (
    <div className="bg-[#fffbfa] py-28 px-8">
      <div className="generalDiv flex flex-col items-center justify-center">
        <div className="title">
          <h1 className="text-3xl lg:text-6xl text-orange-600 font-bold">
            senieniyibenanlarım
          </h1>
        </div>
        <div className="text mt-8">
          <p className="text-lg lg:text-xl text-gray-600 font-medium">
            Her sene bize güvenen 100.000 + öğrenci ile günün 24 saati hep
            içerik tüketiliyor.
          </p>
        </div>
      </div>
    </div>
  );
}

export default SixthSection;
