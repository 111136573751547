import React from "react";

function FifthSection() {
  return (
    <div className="bg-black py-12">
      <div className="container mx-auto flex flex-col lg:flex-row justify-center items-center space-y-6 lg:space-y-0 lg:space-x-6 px-4">
        {/* İlk Kutu */}
        <div className="bg-[#ffeda3] p-6 rounded-lg shadow-md w-full lg:w-1/3 min-h-[200px] flex flex-col items-start justify-center">
          <h3 className="text-base lg:text-lg font-bold text-black">
            Binlerce konu her an yanında
          </h3>
          <p className="text-gray-800 mt-2 font-normal text-sm lg:text-base">
            Zengin kütüphanemizde her branşta öğrenci için mutlaka binlerce
            içerik var. İstediğin konuyu istediğin kadar dinleyebilir ve
            okuyabilirsin.
          </p>
        </div>

        {/* İkinci Kutu */}
        <div className="bg-[#b9e6ff] p-6 rounded-lg shadow-md w-full lg:w-1/3 min-h-[200px] flex flex-col items-start justify-center">
          <h3 className="text-base lg:text-lg font-bold text-black">
            Sorun yaşama diye
          </h3>
          <p className="text-gray-800 mt-2 font-normal text-sm lg:text-base">
            Dilediğin her an bana yazabilirsin, sorabilirsin hatta canın her
            sıkıldığında mesaj bile atabilirsin.
          </p>
        </div>

        {/* Üçüncü Kutu */}
        <div className="bg-[#fff2f1] p-6 rounded-lg shadow-md w-full lg:w-1/3 min-h-[200px] flex flex-col items-start justify-center">
          <h3 className="text-base lg:text-lg font-bold text-black">
            tovivu Original
          </h3>
          <p className="text-gray-800 mt-2 font-normal text-sm lg:text-base">
            Başka bir yerde bulamayacağın, tovivu kullanıcılarına özel
            oluşturulmuş benzersiz hikayeler.
          </p>
        </div>
      </div>
    </div>
  );
}

export default FifthSection;
