import React from "react";
import { Link } from "react-router-dom";

function Navbar() {
  return (
    <nav className="bg-black text-white py-4">
      <div className=" flex justify-between items-center lg:px-10 mx-5">
        {/* Logo */}
        <div className="text-4xl font-bold text-yellow-500">
          t<span className="text-white">ov</span>i
          <span className="text-white">vu</span>
        </div>

        {/* Navigation Links */}
        <div className="flex">
          <a className="hover:text-orange-600 font-semibold my-3 mx-5 hidden lg:block">
            Sesli Dersler
          </a>
          <a className="hover:text-orange-600 font-semibold my-3 mx-5 hidden lg:block">
            Özel Playlistler
          </a>
          <a className="hover:text-orange-600 font-semibold my-3 mx-5 hidden lg:block">
            YKS İçerikleri
          </a>
          <a className="hover:text-orange-600 font-semibold my-3 mx-5 hidden lg:block">
            KPSS İçerikleri
          </a>
          <a className="hover:text-orange-600 font-semibold my-3 mx-5 hidden lg:block">
            LGS İçerikleri
          </a>
          <a className="hover:text-orange-600 font-semibold my-3 mx-5 hidden lg:block">
            Lise İçerikleri
          </a>
          <Link
            href="#contact"
            target="_blank"
            to={"https://wa.me/+908503029072"}
            className="text-orange-500 font-semibold hover:text-orange-600 my-3 mx-5"
          >
            İletişime Geç
          </Link>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
