import React from "react";
import i1 from "../images/section1/firstSection1.png";
import i2 from "../images/section1/firstSection2.png";
import i3 from "../images/section1/firstSection3.png";
import i4 from "../images/section1/firstSection4.png";
import i5 from "../images/section1/firstSection5.png";
import i6 from "../images/section1/firstSection6.png";
import i7 from "../images/section1/firstSection7.png";
import i8 from "../images/section1/firstSection8.png";

function FirstSection() {
  return (
    <div className="bg-black text-white py-16 pl-4 max-w-[100vw] overflow-hidden">
      <div className="bigRow lg:px-8">
        <div className="imageRow flex flex-col lg:flex-row">
          <div className="flex flex-row">
            <div className="flex items-center justify-center lg:h-[300px] lg:w-[400px] h-[150px] w-1/2 m-2 lg:m-5">
              <img
                src={i1}
                alt="Image 1"
                className="w-full h-full object-cover rounded-3xl"
              />
            </div>
            <div className="flex items-center justify-center lg:h-[300px] lg:w-[400px] h-[150px] w-1/2 m-2 lg:m-5">
              <img
                src={i2}
                alt="Image 2"
                className="w-full h-full object-cover rounded-3xl"
              />
            </div>
          </div>
          <div className="flex flex-row">
            <div className="flex items-center justify-center lg:h-[300px] lg:w-[400px] h-[150px] w-1/2 m-2 lg:m-5">
              <img
                src={i8}
                alt="Image 3"
                className="w-full h-full object-cover rounded-3xl"
              />
            </div>
            <div className="flex items-center justify-center lg:h-[300px] lg:w-[400px] h-[150px] w-1/2 m-2 lg:m-5">
              <img
                src={i4}
                alt="Image 4"
                className="w-full h-full object-cover rounded-3xl"
              />
            </div>
          </div>
          <div className="flex flex-row">
            <div className="flex items-center justify-center lg:h-[300px] lg:w-[400px] h-[150px] w-1/2 m-2 lg:m-5">
              <img
                src={i5}
                alt="Image 5"
                className="w-full h-full object-cover rounded-3xl"
              />
            </div>
            <div className="flex lg:hidden items-center justify-center lg:h-[300px] lg:w-[400px] h-[150px] w-1/2 m-2 lg:m-5">
              <img
                src={i6}
                alt="Image 1"
                className="w-full h-full object-cover rounded-3xl"
              />
            </div>
          </div>
        </div>
        <div className="textArea flex flex-row w-full">
          <div className="text lg:w-1/2 flex flex-col justify-start m-5">
            <h1 className="text-2xl lg:text-5xl font-bold text-orange-600">
              Özgürlüğün Peşinden
            </h1>
            <h1 className="text-2xl lg:text-5xl font-bold text-orange-600 lg:mt-4">
              Emin ve Koşar Adımlarla
            </h1>
            <h1 className="text-2xl lg:text-5xl font-bold text-yellow-500 mt-4">
              t<span className="text-white">ov</span>i
              <span className="text-white">vu</span>
              <span className="text-orange-600">’</span>la
            </h1>
            <h2 className="text-xl lg:text-3xl text-white mt-4 font-bold">
              Güvende kal, mutlu hisset
            </h2>
          </div>
          <div className="w-1/2 image lg:flex flex-row justify-start hidden">
            <div className="flex items-center justify-center h-[300px] w-[400px] m-5">
              <img
                src={i6}
                alt="Image 1"
                className="w-full h-full object-cover rounded-3xl"
              />
            </div>
            <div className="flex items-center justify-center h-[300px] w-[400px] m-5">
              <img
                src={i7}
                alt="Image 2"
                className="w-full h-full object-cover rounded-3xl"
              />
            </div>
            <div className="flex items-center justify-center h-[300px] w-[400px] m-5">
              <img
                src={i3}
                alt="Image 3"
                className="w-full h-full object-cover rounded-3xl"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FirstSection;
