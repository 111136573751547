import React, { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import PaymentModal from "./paymentModal";
import FirstSection from "./FirstSection";
import Navbar from "./Navbar";
import SecondSection from "./SecondSection";
import ThirdSection from "./ThirdSection";
import FourthSection from "./FourthSection";
import FifthSection from "./FifthSection";
import SixthSection from "./SixthSection";
import SeventhSection from "./SeventhSection";
import FooterNew from "./FooterNew";
import { Link } from "react-router-dom";
import wp from "../images/wp.png";

const backendUrl = process.env.REACT_APP_BACKEND_URL;

function Main({ openPaymentPage }) {
  const [packets, setPackets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isLoadingPayment, setIsLoadingPayment] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [currentLevel, setCurrentLevel] = useState("");
  const [discountAmount, setDiscountAmount] = useState("");
  const [verifyCode, setVerifyCode] = useState("");
  const [currentPacket, setCurrentPacket] = useState("");
  const [idNumber, setIdNumber] = useState("");
  const [paymentPageContent, setPaymentPageContent] = useState("");

  const [packetInfos, setPacketInfos] = useState({
    id: "",
    title: "",
    price: "",
  });

  const checkUserPacketStatus = async (phoneNumber) => {
    const rawPhoneNumber = phoneNumber.replace(/\s/g, "");
    try {
      const response = await axios.get(
        `${backendUrl}/check-user-packet-status?phone=${rawPhoneNumber}&country_code=90`
      );
      const { current_level, new_user, packet_id } = response.data.data;
      setCurrentPacket(packet_id);
      setCurrentLevel(current_level);
    } catch (error) {
      console.error("Error fetching user packet status:", error);
    }
  };

  const handleBuy = async (data) => {
    const rawPhoneNumber = data?.phoneNumber?.replace(/\s/g, "");
    if (packetInfos?.id === 1) {
      Swal.fire({
        title: "Emin misiniz?",
        text: "Free pakete geçmek istediğinize emin misiniz? Var olan tüm paketleriniz iptal edilecek.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Evet",
        cancelButtonText: "Hayır",
      }).then(async (result) => {
        if (result.isConfirmed) {
          setIsLoadingPayment(true);
          const requestBody = {
            verifcation_code: verifyCode,
            packet_id: packetInfos?.id,
            gsmNumber: "+90" + rawPhoneNumber,
          };

          try {
            const response = await axios.post(
              `${backendUrl}/cancel-plan`,
              requestBody
            );
            setIsLoadingPayment(false);
            checkUserPacketStatus(rawPhoneNumber);
            if (response?.data?.status) {
              Swal.fire({
                title: "Başarılı",
                text: "Başarılı bir şekilde Free pakete geçildi!",
                icon: "success",
                showConfirmButton: false,
                timer: 3000,
              });
            }
          } catch (error) {
            setIsLoadingPayment(false);
            Swal.fire({
              title: "Hata",
              text: "Bir hata oluştu, lütfen tekrar deneyin.",
              icon: "error",
            });
          }
        }
      });
    } else {
      try {
        Swal.fire({
          title: "Lütfen bekleyin...",
          html: "Ödeme ekranına yönlendiriliyor.",
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });
        setIsLoadingPayment(true);
        const requestBody = {
          kupon_kodu: data?.discountCode,
          verifcation_code: data?.verifyCode,
          packet_id: packetInfos?.id,
          name: data?.name,
          surname: data?.surname,
          email: data?.email,
          gsmNumber: "+90" + rawPhoneNumber,
          registration_address: data?.address,
          city: data?.city,
        };

        if (idNumber !== "") {
          requestBody.identity_number = idNumber;
        }
        const response = await axios.post(
          `${backendUrl}/init-payment`,
          requestBody
        );
        const paymentPageContent = response.data.payment_page_url;
        if (response.data.Message === "Bu packeti satin alamazsın") {
          Swal.fire({
            title: "Hata",
            text: "Üyeliğiniz sonlanmadan bu pakete geçemezsiniz!",
            icon: "error",
            showConfirmButton: false,
            timer: 3000,
          });
        } else {
          if (paymentPageContent) {
            if (paymentPageContent.startsWith("https://")) {
              window.open(paymentPageContent, "_self");
              Swal.close();
            } else {
              openPaymentPage(paymentPageContent);
              Swal.close();
            }
          } else {
            console.error("Payment page URL not found in the response.");
          }
        }
      } catch (error) {
        console.error("Error during purchase:", error);
        // Swal ile hata mesajını göster
        Swal.fire({
          title: "Hata!",
          text: `Ödeme işlemi sırasında bir sorun oluştu. Lütfen tekrar deneyin. ${error}`,
          icon: "error",
          confirmButtonText: "Tamam",
        });
      } finally {
        setIsLoadingPayment(false);
      }
    }
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${backendUrl}/packet?page=1&per_page=100`
      );
      const sortedPackets = response.data.data.items.sort(
        (a, b) => a.order_num - b.order_num
      );
      setPackets(sortedPackets);
    } catch (error) {
      console.error("Error fetching packet data:", error);
    } finally {
      setLoading(false);
    }
  };

  // Scriptleri çalıştırmak için fonksiyon
  const runScripts = (element) => {
    const scripts = element.querySelectorAll("script");
    scripts.forEach((script) => {
      const newScript = document.createElement("script");
      newScript.type = script.type;
      if (script.src) {
        newScript.src = script.src;
      } else {
        newScript.innerHTML = script.innerHTML;
      }
      document.body.appendChild(newScript);
    });
  };

  // Modal içeriği render edildiğinde çalıştırılacak kod
  useEffect(() => {
    if (paymentPageContent) {
      const paymentDivElement = document.getElementById(
        "iyzipay-checkout-form"
      );
      if (paymentDivElement) {
        runScripts(paymentDivElement);
      }
    }
  }, [paymentPageContent]);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Navbar />
      <FirstSection />
      <SecondSection
        currentLevel={currentLevel}
        currentPacket={currentPacket}
        discountAmount={discountAmount}
        handleBuy={handleBuy}
        packets={packets}
        setPacketInfos={setPacketInfos}
        setOpenModal={setOpenModal}
      />
      <ThirdSection />
      <FourthSection />
      <FifthSection />
      <SixthSection />
      <SeventhSection />
      <FooterNew />
      <Link target="_blank" to={"https://wa.me/+908503029072"}>
        <img
          className="whatsapp fixed w-16 h-16 bottom-4 right-4 z-30"
          src={wp}
          alt="WhatsApp"
        />
      </Link>
      <PaymentModal
        setCurrentLevel={setCurrentLevel}
        setCurrentPacket={setCurrentPacket}
        setDiscountAmount={setDiscountAmount}
        setOpenModal={setOpenModal}
        openModal={openModal}
        packetInfos={packetInfos}
        discount={discountAmount}
        handleBuy={handleBuy}
      />
    </>
  );
}

export default Main;
