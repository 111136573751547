import React from "react";

const PacketBox = ({
  packet,
  current,
  handleBuy,
  discount,
  currentPacket,
  setOpenModal,
  setPacketInfos,
}) => {
  const handlePrintFeature = (feature) => {
    try {
      const correctedDescription = feature.replace(/'/g, '"');
      const parsedDescription = JSON.parse(correctedDescription);
      return parsedDescription;
    } catch (e) {
      console.error(e);
    }
  };

  const formatPrice = (price) => {
    return price.toLocaleString("tr-TR", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const applyDiscount = (price) => {
    const isDiscountApplicable =
      discount &&
      discount.packets &&
      discount.packets.some(
        (discountPacket) => discountPacket.packet_id === packet.id
      );

    if (!isDiscountApplicable || discount.discount <= 0) return price;

    let discountedPrice = price;

    if (discount.type === "yuzde") {
      discountedPrice = price - price * (discount.discount / 100);
    } else if (discount.type === "miktar") {
      discountedPrice = price - discount.discount;
    }

    return discountedPrice > 0 ? discountedPrice : 0;
  };

  const finalPrice = applyDiscount(packet.price);

  const clickPacket = () => {
    setOpenModal(true);
    setPacketInfos({
      id: packet.id,
      title: packet.name,
      price: packet.price,
    });
  };

  return (
    <div className="box overflow-hidden relative bg-white text-black p-6 lg:w-96 lg:text-[1vw] text-sm min-h-[350px] flex flex-col items-center justify-center rounded-lg border border-[#a87e7d] ">
      <div className="relative z-10">
        <h2 className="font-bold mb-2 text-3xl">{packet?.name}</h2>
        <p className="text-sm mb-4 font-medium">{packet?.packet_text}</p>
        <div className="flex flex-row items-center justify-start my-5">
          <h1 className="text-sm lg:text-xl font-bold text-orange-600">
            {finalPrice <= 0 ? "Ücretsiz" : formatPrice(finalPrice) + "₺"}
          </h1>
          <h1 className="text-sm lg:text-base font-medium ml-3 text-black">
            {packet.name === "Free"
              ? "3 gün boyunca"
              : packet.name === "Aylık Premium"
              ? "30 gün boyunca"
              : packet.name === "3 Aylık Premium"
              ? "90 gün boyunca"
              : packet.name === "6 Aylık Premium"
              ? "180 gün boyunca"
              : packet.name === "12 Aylık Premium"
              ? "365 gün boyunca"
              : ""}
          </h1>
        </div>
        <ul className="mb-6 text-sm list-disc mx-4">
          {handlePrintFeature(packet?.description)?.map((feature, index) => (
            <li className="my-2 font-normal text-gray-600" key={index}>
              {feature}
            </li>
          ))}
        </ul>

        {packet?.id !== 1 && (
          <button
            type="button"
            disabled={currentPacket === packet?.id ? true : false}
            className={`py-2 px-12 mx-auto rounded-full flex items-center justify-center lg:text-[0.8vw] font-medium transition-all duration-300 bg-[#101010] text-white`}
            onClick={clickPacket}
          >
            Aboneliği Başlat
          </button>
        )}
      </div>
    </div>
  );
};

export default PacketBox;
