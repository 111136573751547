import React from "react";
import { Link } from "react-router-dom";
import ig from "../images/ig.png";
import tw from "../images/twiiter.png";
import lk from "../images/lk.png";

function FooterNew() {
  return (
    <footer className="bg-[#ffffff] p-6 text-black">
      <div className="lg:px-5 py-12 mx-auto flex flex-col lg:flex-row justify-between items-center">
        {/* Sol Kısım */}
        <div className="text-gray-700 text-sm lg:text-left">
          <h4 className="text-orange-600 font-bold text-lg mb-2">
            Tovivu Bilişim Yazılım Dijital ve Medya Yayıncılık Anonim Şirketi
          </h4>
          <p className="font-normal my-3">
            e-posta adresimiz:{" "}
            <a href="mailto:destek@tovivu.com" className="text-black">
              destek@tovivu.com
            </a>
          </p>
          <p className="font-normal my-3">
            Whatsapp destek:{" "}
            <a href="tel:+908503029072" className="text-black">
              +90 850 302 9072
            </a>
          </p>

          <h1 className="font-normal my-5 underline">
            <Link to="https://tovivu.com/gizlilik-politikasi/">
              Gizlilik Politikaları
            </Link>
          </h1>
          <h1 className="font-normal my-5 underline">
            <Link to="https://tovivu.com/tovivu-kisisel-satis-sozlesmesi/">
              Kişisel Satış Sözleşmesi
            </Link>
          </h1>
          <h1 className="font-normal my-5 underline">
            <Link to="https://tovivu.com/tovivu-iptal-ve-iade-kosullari/">
              İptal ve İade Koşulları
            </Link>
          </h1>
          <h1 className="font-normal my-5 underline">
            <Link to="https://tovivu.com/tovivu-mesafeli-satis-sozlesmesi/">
              Mesafeli Satış Sözleşmesi
            </Link>
          </h1>
          <h1 className="font-normal my-5 underline">
            <Link to="https://tovivu.com/kullanim-sartlari/">
              Kullanım Şartları
            </Link>
          </h1>
          <h1 className="font-normal mt-12">
            Copyright © 2024. Powered by Tovivu
          </h1>
        </div>

        {/* Sağ Kısım */}
        <div className="mt-6 lg:mt-0 lg:text-right">
          <div className="flex justify-center lg:justify-end space-x-4 mb-4 text-black">
            <Link to="https://www.linkedin.com/company/tovivu/" target="_blank">
              <img src={lk} alt="lk" className="w-12 h-12" />
            </Link>
            <Link to="https://x.com/tovivu_TR" target="_blank">
              <img src={tw} alt="tw" className="w-12 h-12" />
            </Link>
            <Link to="https://www.instagram.com/tovivu_tr/" target="_blank">
              <img src={ig} alt="ig" className="w-12 h-12" />
            </Link>
          </div>
          <p className="text-black text-xl font-medium">Türkiye | Kocaeli</p>
          <div className="flex justify-center lg:justify-end space-x-2 mt-4">
            <img
              src="https://i.hizliresim.com/slfh0ww.png"
              alt="Visa"
              className="w-24 h-24 mr-5"
            />
            <img
              src="https://i.hizliresim.com/odww0gx.png"
              alt="MasterCard"
              className="w-24 h-24"
            />
          </div>
        </div>
      </div>
    </footer>
  );
}

export default FooterNew;
