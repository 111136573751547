import * as React from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import "./App.css";
import Main from "./components/main";
import PaymentSuccesful from "./components/paymentSuccesful";
import PaymentPage from "./components/paymentPage";

function App() {
  const navigate = useNavigate();

  const openPaymentPage = (scriptContent) => {
    if (scriptContent) {
      navigate("/payment-page", { state: { scriptCode: scriptContent } });
    }
  };

  return (
    <Routes>
      <Route path="/*" element={<Main openPaymentPage={openPaymentPage} />} />
      <Route path="/payment-succesful" element={<PaymentSuccesful />} />
      <Route path="/payment-page" element={<PaymentPage />} />
    </Routes>
  );
}

export default App;
