import React from "react";
import i1 from "../images/section7/seventSection1.png";
import check from "../images/checkOrange.png";

function SeventhSection() {
  return (
    <div className="bg-[#fff2f1] flex flex-col lg:flex-row">
      <div className="leftArea flex flex-row items-center justify-center lg:w-1/2 p-8">
        <div className="generalDiv">
          <div className="titleAndText">
            <h1 className="text-3xl lg:text-6xl text-orange-600 font-bold">
              Dinle ya da izle
            </h1>
            <p className="text-black mt-5 font-medium">
              Sınav süreçlerinde büyülü bir modele hızla başla
            </p>
          </div>
          <div className="featuresArea mt-8">
            <div className="features">
              <ul className="mt-6 text-sm lg:text-lg text-gray-600 font-light flex flex-col justify-start">
                <li className="flex my-2 items-center justify-start">
                  <img src={check} className="w-[34px] h-8 mr-3" alt="check1" />
                  Aylık veya sınava kadar olan süreye özel paketler
                </li>
                <li className="flex my-2 items-center justify-start">
                  <img src={check} className="w-[34px] h-8 mr-3" alt="check2" />
                  Binlerce sesli ve görüntülü podcast
                </li>
                <li className="flex my-2 items-center justify-start">
                  <img src={check} className="w-[34px] h-8 mr-3" alt="check3" />
                  Bir premium pakete birden fazla aynı anda erişim
                </li>
                <li className="flex my-2 items-center justify-start">
                  <img src={check} className="w-[34px] h-8 mr-3" alt="check4" />
                  Ücretsiz dinle ve test et. Beğenirsen premium ile avantajlı ol
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="rightArea lg:w-1/2">
        <img src={i1} className="w-full h-full" alt="i1" />
      </div>
    </div>
  );
}

export default SeventhSection;
